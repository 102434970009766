import React, { useState, useEffect, useCallback, useTransition } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useNavigate, useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { MediaQuery, DeviceSize, NoSelect, Palette } from 'core/config';
import { useIsDesktop, useThrottle, useLockedBody } from 'view/hooks';
import { RoutePath } from 'core/router/AppRouter';

import { ReactComponent as CallastoLogo } from 'assets/icons/callasto_logo.svg';

enum BurgerColour {
  WHITE = Palette.WHITE,
  BLUE = Palette.BLUE,
  RED = Palette.RED,
}

const Navbar: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isLargeScreen] = useIsDesktop();
  const [isMobile, setIsMobile] = useState(false);
  const [closeLinkTree, setCloseLinkTree] = useState(true);
  const [navbarIsExpanded, setNavbarIsExpanded] = useState(isLargeScreen);

  const [searchParams] = useSearchParams();
  const linkTree = searchParams.get('lnktree');
  const linkTreeValid = linkTree
    ? linkTree === 'yeah'
    : pathname === RoutePath.LINKS;

  const [locked, setLocked] = useLockedBody(linkTreeValid, 'root');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isPending, startTransition] = useTransition();

  const [hideScrollUp, setHideScrollUp] = useState(false);
  const [show, setShow] = useState<BurgerColour>(BurgerColour.WHITE);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    setIsMobile(window.innerWidth < DeviceSize.tablet);
  }, []);

  const handleHamburgerClick = () => {
    setCloseLinkTree(false);
    setLocked(!locked);
    setNavbarIsExpanded(!navbarIsExpanded);
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    setNavbarIsExpanded(linkTreeValid ? true : isLargeScreen);

    if (linkTreeValid) {
      timeout = setTimeout(() => setCloseLinkTree(false), 3000);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [isLargeScreen, linkTreeValid]);

  const controlNavbar = useThrottle(
    () => {
      if (window.scrollY <= 100) {
        setShow(BurgerColour.WHITE);
        setHideScrollUp(false);
      } else if (window.scrollY > lastScrollY) {
        setShow(BurgerColour.RED);
        setHideScrollUp(true);
      } else {
        setShow(BurgerColour.WHITE);
      }

      setLastScrollY(window.scrollY);
    },
    150,
    [lastScrollY]
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlNavbar);

      return () => {
        window.removeEventListener('scroll', controlNavbar);
      };
    }
  }, [controlNavbar, lastScrollY, isMobile]);

  const handleLinkClick = useCallback(
    (href: string) => (e: any) => {
      e?.preventDefault();
      startTransition(() => {
        navigate(href);
        if (isLargeScreen) return;

        setLocked(!locked);
        setNavbarIsExpanded(false);
      });
    },
    [navigate, setNavbarIsExpanded, setLocked, isLargeScreen, locked]
  );

  const handleKeyPress = useCallback(
    (href: string) => (e: React.KeyboardEvent<HTMLAnchorElement>) => {
      if (e.key === 'Enter') {
        startTransition(() => {
          navigate(href);
          if (isLargeScreen) return;

          setLocked(!locked);
          setNavbarIsExpanded(false);
        });
      }
    },
    [navigate, setNavbarIsExpanded, setLocked, isLargeScreen, locked]
  );

  const handleScrollUp = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const navLinks = [
    {
      name: 'Home',
      linkTo: '/',
    },
    {
      name: 'Releases',
      linkTo: '/releases',
    },
    {
      name: 'Newsletter',
      linkTo: '/newsletter',
    },
    {
      name: 'Contact',
      linkTo: '/contact',
    },
    {
      name: 'Bio',
      linkTo: '/bio',
    },
    {
      name: 'Fyi',
      linkTo: '/fyi',
    },
    {
      name: 'Apple',
      href: 'https://music.apple.com/ca/artist/callasto/1672851829',
    },
    {
      name: 'Spotify',
      href: 'https://open.spotify.com/artist/2S3X4VHwKM1nY0IrZHn4qi?si=3SjkjXm1QmSAcJt54Yql8A',
    },
    {
      name: 'Soundcloud',
      href: 'https://soundcloud.com/callasto',
    },
    {
      name: 'Disconnected',
      href: 'https://open.spotify.com/playlist/4x5ENx52PXNeRgbLuzDceI?si=c772ff3a8bcf4932',
    },
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/callasto_',
    },
    {
      name: 'Bandcamp',
      href: 'https://callasto.bandcamp.com',
    },
    {
      name: 'Youtube',
      href: 'https://www.youtube.com/callasto',
    },
  ];

  // callasto.com/[releases]
  const slugPath = pathname.split('/').slice(0, 2).join('/');

  return (
    <>
      <Header>
        <HamburgerContainer isOpen={navbarIsExpanded}>
          <HamburgerAnimation
            isOpen={navbarIsExpanded}
            onClick={handleHamburgerClick}
            fade={show}
            aria-label="Toggle mobile navigation menu"
          >
            <div className="line-menu half start"></div>
            <div className="line-menu"></div>
            <div className="line-menu half end"></div>
          </HamburgerAnimation>
          <CloseText isOpen={navbarIsExpanded}>
            {linkTreeValid && closeLinkTree ? '_networks-detected' : 'EXIT'}
          </CloseText>
        </HamburgerContainer>
        <CallastoLogoButton
          aria-label="Click callasto logo to navigate back to home page"
          onClick={handleLinkClick('/')}
          isHome={pathname !== RoutePath.LINKS && pathname !== RoutePath.ROOT}
        >
          <CallastoLogo width="75px" height="75px" title="callasto logo" />
        </CallastoLogoButton>
      </Header>
      <Container isExpanded={navbarIsExpanded}>
        <NavLinks>
          {navLinks.map((link, i) => {
            if (link.linkTo)
              return (
                <li key={`navlink-${link.name}__${i}`}>
                  <NavLink
                    aria-label={link.name}
                    href={link.linkTo}
                    onClick={handleLinkClick(link.linkTo)}
                    onKeyDown={handleKeyPress(link.linkTo)}
                    active={slugPath === link.linkTo}
                    {...(slugPath === link.linkTo
                      ? { 'aria-current': 'page' }
                      : null)}
                  >
                    {link.name}
                  </NavLink>
                </li>
              );

            return (
              <li key={`navlink-${link.name}__${i}`}>
                <NavLink
                  isHref
                  aria-label={link.name}
                  href={link.href}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {link.name}
                  <Arrow>&#10132;</Arrow>
                </NavLink>
              </li>
            );
          })}
        </NavLinks>
      </Container>
      {pathname.includes(RoutePath.RELEASES) ? (
        <ScrollUp
          onClick={handleScrollUp}
          fade={hideScrollUp}
          disabled={!hideScrollUp}
          aria-label="Scroll back to top of page"
        >
          &#x21e1;
        </ScrollUp>
      ) : null}
      <FixedSidebar
        isPageLoaded={
          pathname !== RoutePath.LINKS && pathname !== RoutePath.ROOT
        }
        isMobile={isMobile}
      >
        <FillerBlock top />
        <TextContainer isMobile={isMobile}>CALLASTO</TextContainer>
        <FillerBlock />
      </FixedSidebar>
      <BottomFade />
    </>
  );
};

const flicker = keyframes`
  0%,5%,10.5%,
  20%,
  40.5%,
  60%,
  80.5%,
  100% {
    fill: ${Palette.RED};
    stroke: ${Palette.RED};
  }

  5.5%,
  10%,
  20.5%,40%,
  60.5%,
  80%,
  99.5%{
    fill: ${Palette.WHITE};
    stroke: ${Palette.WHITE};
  }
`;

const Header = styled.header`
  position: fixed;
  width: 100vw;
  height: 9vh;
  display: flex;
  align-items: center;
  padding-left: 25px;
  background-image: linear-gradient(to bottom, #000 0%, rgba(0, 0, 0, 0) 100%);
  z-index: 200;
`;

const CallastoLogoButton = styled.button<{ isHome: boolean }>`
  display: none;
  border: none;
  opacity: 0.93;
  background-color: transparent;
  transition: all 500ms ease-in-out;
  cursor: pointer;

  :hover {
    opacity: 1;
  }

  .cls-3,
  .cls-4 {
    transition: all 500ms ease-in-out;
  }

  :hover .cls-4 {
    fill: ${Palette.CYAN_BLUE};
    stroke: ${Palette.CYAN_BLUE};
  }

  @media ${MediaQuery.tablet} {
    display: inline-block;
    svg {
      height: 100%;
    }
  }

  ${({ isHome }) =>
    isHome
      ? css`
          .cls-3 {
            animation: ${flicker} 10s ease-in infinite;
            animation-delay: 3s;
          }
        `
      : ''}
`;

const HamburgerContainer = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;

  @media ${MediaQuery.tablet} {
    display: none;
  }
`;

const HamburgerAnimation = styled.button<{
  isOpen: boolean;
  fade: BurgerColour;
}>`
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  opacity: ${({ fade }) =>
    fade === BurgerColour.WHITE ? 1 : fade === BurgerColour.RED ? 0.93 : 1};

  transition: opacity 0.5s ease-in-out;
  cursor: pointer;

  @media (hover: hover) and (pointer: fine) {
    :hover {
      opacity: 0.85;
      .line-menu {
        background-color: ${({ fade, isOpen }) =>
          fade === BurgerColour.WHITE || isOpen ? Palette.RED : Palette.WHITE};
      }
    }
  }

  ${({ isOpen }) =>
    isOpen
      ? css`
          transform: rotate(-45deg);
          opacity: 1;

          .line-menu.start {
            transform: rotate(-90deg) translateX(3px);
          }

          .line-menu.end {
            transform: rotate(-90deg) translateX(-3px);
          }
        `
      : ''};

  .line-menu {
    transition: background-color 0.5s ease-in-out;
    background-color: ${({ fade, isOpen }) => (isOpen ? Palette.RED : fade)};
    border-radius: 5px;
    width: 100%;
    height: 6px;
  }

  .line-menu.half {
    width: 50%;
  }

  .line-menu.start {
    transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57),
      background-color 0.6s ease-in-out;
    transform-origin: right;
  }

  .line-menu.end {
    align-self: flex-end;
    transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57),
      background-color 0.6s ease-in-out;
    transform-origin: left;
  }
`;

const CloseText = styled.span<{ isOpen: boolean }>`
  margin-top: 1px;
  margin-left: 10px;
  font-size: 11px;
  letter-spacing: 3px;
  color: ${Palette.WHITE};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: opacity 0.5s ease-in-out ${({ isOpen }) => (isOpen ? 0.3 : 0)}s;
`;

const BottomFade = styled.div`
  position: fixed;
  width: 100vw;
  height: 9vh;
  background-image: linear-gradient(to top, #000 0%, rgba(0, 0, 0, 0) 100%);
  z-index: 200;
  bottom: 0;
`;

const Container = styled.aside<{ isExpanded: boolean }>`
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  left: 0;
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  background-color: rgba(0, 0, 0, 0.75);
  pointer-events: ${({ isExpanded }) => (isExpanded ? 'initial' : 'none')};
  transition: opacity 0.5s ease-in-out 0.1s,
    ${({ isExpanded }) =>
      !isExpanded
        ? 'transform 0.5s ease-in-out 1s, visibility 1ms ease-in-out 0.7s'
        : 'transform 0.1s ease-in-out 0.1s, visibility 1ms ease-in-out'};
  transform: ${({ isExpanded }) =>
    isExpanded ? 'translate(0%, 0%)' : 'translate(-100%, 0%)'};
  visibility: ${({ isExpanded }) => (isExpanded ? 'visible' : 'hidden')};
  opacity: ${({ isExpanded }) => (isExpanded ? 1 : 0)};

  @media ${MediaQuery.tablet} {
    width: 25vw;
    max-width: 500px;
    background-color: transparent;
  }

  ${NoSelect}
`;

const NavLinks = styled.ul`
  z-index: 9000;
  height: 75vh;
  max-height: 500px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  padding: 0;

  margin: auto;

  list-style-type: none;

  @media ${MediaQuery.tablet} {
    background: none;
    align-items: flex-start;
    max-height: 600px;
  }
`;

const NavLink = styled.a<{ active?: boolean; isHref?: boolean }>`
  font-weight: bold;
  font-size: 1.25em;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.2rem;

  cursor: pointer;
  text-transform: uppercase;
  transition: color 0.4s ease-in-out, transform 0.6s ease-in-out;
  color: ${({ active }) => (active ? Palette.RED : Palette.WHITE)};

  :before {
    content: '/ ';
  }

  :hover {
    color: ${({ isHref }) => (isHref ? Palette.TURQUOISE : Palette.CYAN_BLUE)};
    transform: scale(1.05);
  }

  @media ${MediaQuery.tablet} {
    font-size: 0.8em;
    transform-origin: left;
  }
  @media ${MediaQuery.laptopS} {
    font-size: 0.95em;
  }
`;

const Arrow = styled.span`
  display: inline-block;
  transform: rotate(-45deg) translate(5px, 2px);
`;

const ScrollUp = styled.button<{ fade: boolean }>`
  position: fixed;
  height: 50px;
  width: 50px;
  bottom: 3vh;
  right: 6vw;
  font-size: 30px;
  border: 2px solid ${Palette.RED};
  border-radius: 12px;
  z-index: 300;
  background-color: rgba(0, 0, 0, 0.5);
  color: ${Palette.WHITE};
  transition: opacity 0.8s ease-in-out, color 0.8s ease-in-out;
  opacity: ${({ fade }) => (fade ? 1 : 0)};
  cursor: pointer;

  :disabled {
    pointer-events: none;
  }

  :hover {
    background-color: rgba(0, 0, 0, 0.85);
    color: ${Palette.CYAN_BLUE};
  }

  @media ${MediaQuery.tablet} {
    bottom: 5vh;
    right: 15vw;
  }
`;

const FixedSidebar = styled.div<{ isPageLoaded: boolean; isMobile: boolean }>`
  position: fixed;
  height: 100vh;
  width: 6vh;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  right: 0;
  top: 0;
  z-index: 1000;
  padding: 0 0.75em;
  background-color: ${Palette.WHITE};
  color: ${Palette.BLACK};
  cursor: not-allowed;
  transition: opacity 0.8s ease-in-out, color 1s ease-in-out;
  opacity: ${({ isPageLoaded }) => (isPageLoaded ? 0.97 : 0)};
  :hover {
    color: ${Palette.BLUE};
  }

  @media ${MediaQuery.tablet} {
    display: flex;
  }
  ${({ isMobile }) =>
    isMobile
      ? css`
          @media screen and (orientation: landscape) {
            width: 10vh;
          }
        `
      : ``}

  @media ${MediaQuery.laptopS} {
    width: 8vh;
  }

  @media ${MediaQuery.desktopS} {
    width: 10vh;
  }
`;

const TextContainer = styled.div<{ isMobile: boolean }>`
  width: 100%;
  max-width: 36px;
  overflow-wrap: break-word;
  font-size: 2.5rem;
  text-align: center;
  padding: 0.75em 0;
  border-radius: 3px;
  ${({ isMobile }) =>
    isMobile
      ? css`
          @media screen and (orientation: landscape) {
            font-size: 1.5rem;
          }
        `
      : ``}

  @media ${MediaQuery.laptopS} {
    font-size: 2.5rem;
  }
`;

const FillerBlock = styled.div<{ top?: boolean }>`
  height: 100%;
  width: 100%;
  background-color: ${Palette.BLACK};
  border-radius: ${({ top }) => (top ? '0 0 3px 3px' : '3px 3px 0 0')};
`;

export default Navbar;
