import React, { useState, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';

import {
  isValidEmail,
  isValidUsername,
  DEFAULT_ERROR_MESSAGE,
  BACKEND_BASE_URI,
  FETCH_TIME_DURATION,
  Route,
} from 'core/utilities';
import { useForm, useIsMounted, useToast } from 'view/hooks';
import { Palette, MediaQuery } from 'core/config';
import {
  Modal,
  Label,
  Tooltip,
  TextInput,
  HelperText,
  ModalStatus,
  ErrorMessage,
  Checkbox as CheckedBox,
  CheckedAnimation,
  CheckBoxContainer,
  CheckboxInput,
  ReCAPTCHA,
} from 'view/components/common';

type NewsLetterSignupProps = {
  overideDisplay?: boolean;
};
export const NewsLetterSignup: React.FunctionComponent<
  NewsLetterSignupProps
> = ({ overideDisplay }) => {
  const recaptchaRef = useRef<any>();
  const isMounted = useIsMounted();
  const toast = useToast();

  const [shouldDisplay, setShouldDisplay] = useState(false);
  const [openSubModal, setOpenSubModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [token, setToken] = useState<string | null>('');
  const [modalErrorMessage, setModalErrorMessage] = useState<string>('');
  const [submitSuccess, setSubmitSuccess] = useState({
    email: '',
    firstName: '',
  });
  const [focus, setFocus] = useState({
    productionList: false,
    acceptedTerms: false,
    releasesList: false,
    eventsList: false,
    merchList: false,
    playList: false,
  });

  const initialValues = {
    email: '',
    firstName: '',
    acceptedTerms: '',
    productionList: '9',
    releasesList: '3',
    eventsList: '7',
    merchList: '4',
    playList: '10',
  };

  useEffect(() => {
    if (overideDisplay) return setShouldDisplay(true);

    const displayNewsletter = localStorage.getItem('displayNewsletter');
    const parseData = displayNewsletter
      ? JSON.parse(displayNewsletter)
      : 'true';

    if (parseData === 'true') return setShouldDisplay(true);
  }, [overideDisplay]);

  const validate = useCallback((values: any) => {
    const errors: { [key: string]: string } = {};

    if (!values.email.trim()) {
      errors.email = 'Please enter your email.';
    }

    if (values.email && !isValidEmail(values.email)) {
      errors.email = 'Please enter a valid email.';
    }

    if (!values.firstName.trim()) {
      errors.firstName = 'Please enter your first name.';
    }

    if (values.firstName && !isValidUsername(values.firstName)) {
      errors.firstName =
        'Please enter an alphanumeric name ( _ . - symbols accepted ).';
    }

    if (
      !values.releasesList &&
      !values.merchList &&
      !values.eventsList &&
      !values.productionList &&
      !values.playList
    ) {
      errors.subscriptions = 'Please choose a minimum of one subscription.';
    }

    if (!values.acceptedTerms) {
      errors.acceptedTerms =
        'Please accept the terms & conditions before subscribing.';
    }

    return errors;
  }, []);

  const onSubmit = async (values: any) => {
    if (!isMounted()) return;
    if (!isValid) return;

    setIsSubmitting(true);
    setOpenSubModal(true);

    const fullySubscribed =
      values.releasesList &&
      values.merchList &&
      values.eventsList &&
      values.productionList;

    const newsletters = [
      ...(values.productionList ? [values.productionList] : []),
      ...(values.releasesList ? [values.releasesList] : []),
      ...(values.eventsList ? [values.eventsList] : []),
      ...(values.merchList ? [values.merchList] : []),
      ...(values.playList ? [values.playList] : []),
      ...(fullySubscribed ? ['8'] : []),
    ];

    const body = {
      newsLetters: newsletters.toString(),
      firstName: values.firstName.trim(),
      email: values.email.trim(),
      token,
    };

    const url = `${BACKEND_BASE_URI}/${Route.SUBSCRIBE}`;

    //@ts-ignore
    const signal = AbortSignal.timeout(FETCH_TIME_DURATION);

    const options: RequestInit = {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(body),
      signal,
    };

    try {
      const response = await fetch(url, options);
      const reply: { email: string; firstName: string } = await response.json();

      if (!reply.email) throw new Error('Failed to send DOI email');

      await localStorage.setItem('displayNewsletter', JSON.stringify(true));

      setSubmitSuccess(reply);
    } catch (error) {
      setSubmitError(true);
      if (signal.aborted && signal.reason) {
        const timeoutMessage = `Newsletter signup failed: timed out at ${
          FETCH_TIME_DURATION / 1000
        } seconds. Check your network connection`;
        setModalErrorMessage(timeoutMessage);
        toast?.showToast(timeoutMessage, 'success');
        return console.error(timeoutMessage);
      }
      setModalErrorMessage(DEFAULT_ERROR_MESSAGE);
    } finally {
      // recaptchaRef.current.reset();
      setIsSubmitting(false);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') return;

    handleChange(e);
  };

  const handleBlurChange = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    const { name } = e.target;
    setFocus({ ...focus, [name]: false });
    handleBlur(e);
  };

  const handleFocusChange = (
    e: React.FocusEvent<HTMLInputElement, Element>
  ) => {
    const { name } = e.target;
    setFocus({ ...focus, [name]: true });
  };

  const handleModalClose = () => {
    setOpenSubModal(false);

    if (submitError) return;

    resetValues();

    recaptchaRef.current.reset();
  };

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    resetValues,
    values,
    errors,
    touched,
    isValid,
  } = useForm({ initialValues, validate, onSubmit });

  const modalMessage = isSubmitting
    ? 'Sending...'
    : submitError
    ? modalErrorMessage
    : submitSuccess.email
    ? `Hey ${submitSuccess.firstName}, an email has been sent to: ${submitSuccess.email}. Please check your primary / promotions inbox or junk mail and confirm your subscription.`
    : DEFAULT_ERROR_MESSAGE;

  if (!shouldDisplay) return null;

  return (
    <React.Fragment>
      <Modal
        show={openSubModal}
        onClose={handleModalClose}
        title="Subscription Status"
        ariaDescribedBy="modal_subscription_status"
        status={
          isSubmitting
            ? ModalStatus.PENDING
            : submitError
            ? ModalStatus.ERROR
            : ModalStatus.SUCCESS
        }
      >
        <ModalContent id="modal_subscription_status">
          <p>{modalMessage}</p>
        </ModalContent>
      </Modal>

      <FormContainer shouldDisplay={shouldDisplay}>
        <Title>Join my mailing list and stay updated!</Title>

        <TextInput
          placeholder="Neo"
          name="firstName"
          aria-label="Enter your first name"
          autocomplete="given-name"
          label="first_name"
          helperText="First name is used to personalize emails :)"
          minLength={1}
          maxLength={100}
          value={values.firstName}
          touched={touched.firstName}
          error={errors.firstName}
          disabled={isSubmitting}
          onChange={handleChange}
          onBlur={handleBlur}
          required
        />

        <TextInput
          placeholder="neo@thematrix.com"
          autocomplete="email"
          type="email"
          name="email"
          label="email"
          aria-label="Enter your email"
          helperText="Provide your email address to subscribe."
          minLength={4}
          maxLength={256}
          onBlur={handleBlur}
          value={values.email}
          onChange={handleChange}
          disabled={isSubmitting}
          touched={touched.email}
          error={errors.email}
          required
        />

        <Hr />

        <SubLabel>Choose your subscriptions:</SubLabel>
        <Column>
          <CheckedBox
            name="releasesList"
            value="3"
            checked={!!values.releasesList}
            focused={focus.releasesList}
            onKeyPress={handleKeyPress}
            onFocus={handleFocusChange}
            onBlur={handleBlurChange}
            onChange={handleChange}
            isSubmitting={isSubmitting}
          >
            {' '}
            | Releases <span>{'>> '}sent monthly</span>
          </CheckedBox>

          <CheckedBox
            name="playList"
            value="10"
            checked={!!values.playList}
            focused={focus.playList}
            onKeyPress={handleKeyPress}
            onFocus={handleFocusChange}
            onBlur={handleBlurChange}
            onChange={handleChange}
            isSubmitting={isSubmitting}
          >
            {' '}
            | Playlists <span>{'>> '}spotify curated</span>
          </CheckedBox>

          <CheckedBox
            name="eventsList"
            value="7"
            checked={!!values.eventsList}
            isSubmitting={isSubmitting}
            focused={focus.eventsList}
            onKeyPress={handleKeyPress}
            onFocus={handleFocusChange}
            onBlur={handleBlurChange}
            onChange={handleChange}
          >
            {' '}
            | Events <span>{'>> '}in the near future..</span>
          </CheckedBox>

          <CheckedBox
            name="merchList"
            value="4"
            checked={!!values.merchList}
            isSubmitting={isSubmitting}
            focused={focus.merchList}
            onKeyPress={handleKeyPress}
            onFocus={handleFocusChange}
            onBlur={handleBlurChange}
            onChange={handleChange}
          >
            {' '}
            | Merch <span>{'>> '}in the near future...</span>
          </CheckedBox>

          <ErrorMessage
            aria-live="polite"
            marginLeft
            active={!!errors.subscriptions}
          >
            {errors.subscriptions ? errors.subscriptions : undefined}
          </ErrorMessage>
        </Column>

        <Hr />

        <Column>
          <CheckBoxContainer>
            <CheckboxInput
              type="checkbox"
              id="termsCheckbox"
              aria-labelledby="terms_checkbox_screen-reader"
              name="acceptedTerms"
              value="1"
              checked={!!values.acceptedTerms}
              onChange={(e) => {
                handleChange(e);
                if (!!values.acceptedTerms && !touched.acceptedTerms) {
                  !touched.acceptedTerms && handleBlur(e);
                }
              }}
              onKeyDown={handleKeyPress}
              onFocus={handleFocusChange}
              onBlur={handleBlurChange}
              disabled={isSubmitting}
              required
            />
            <ScreenReaderLabel
              id="terms_checkbox_screen-reader"
              htmlFor="termsCheckbox"
              aria-hidden="true"
            >
              Accept Terms and Conditions
            </ScreenReaderLabel>
            <Label htmlFor="termsCheckbox" marginAuto>
              <CheckedAnimation
                active={!!values.acceptedTerms}
                focused={focus.acceptedTerms}
              />
            </Label>
            <Column marginLeft>
              <TermStatement>
                I agree to receive these newsletters and accept the data privacy
                statement.
              </TermStatement>
              <HelperText>
                You may unsubscribe at any time by clicking the link in the
                footer of our emails.
              </HelperText>
              <ErrorMessage
                aria-live="polite"
                active={touched.acceptedTerms && !!errors.acceptedTerms}
              >
                {touched.acceptedTerms && errors.acceptedTerms
                  ? errors.acceptedTerms
                  : undefined}
              </ErrorMessage>
            </Column>
          </CheckBoxContainer>
        </Column>

        <Hr />

        <Disclaimer>
          callasto.com will use the information you provide on this form to be
          in touch with you and to provide updates and marketing.
        </Disclaimer>

        <br />

        <Disclaimer>
          We use Sendinblue as our marketing platform. By Clicking below to
          submit this form, you acknowledge that the information you provided
          will be transferred to Sendinblue for processing in accordance with
          their{' '}
          <TermsLink
            target="_blank"
            href="https://www.sendinblue.com/legal/termsofuse/"
            rel="noreferrer"
          >
            terms of use
          </TermsLink>
          .
        </Disclaimer>

        <br />

        {/* <Hr marginTop /> */}

        <ReCAPTCHA
          recaptchaRef={recaptchaRef}
          onChange={(token) => setToken(token)}
        />

        <Tooltip
          disabled={!isValid}
          content="Ensure required fields are completed before subscribing"
        >
          <Button
            type="button"
            disabled={isSubmitting || !isValid}
            onClick={(e) => {
              e.stopPropagation();
              handleSubmit(e);
            }}
          >
            Subscribe{isValid ? '!' : ''}
          </Button>
        </Tooltip>
      </FormContainer>
    </React.Fragment>
  );
};

const FormContainer = styled.form<{ shouldDisplay: boolean }>`
  display: ${({ shouldDisplay }) => (shouldDisplay ? 'flex' : 'none')};
  flex-direction: column;
  border: 2px solid ${Palette.WHITE};
  border-radius: 10px;
  padding: 15px;
  margin: 25px 0;
  width: 300px;
  background: linear-gradient(0.35turn, #000, #171717, #000);
  @media ${MediaQuery.tablet} {
    width: 400px;
  }
`;

const Column = styled.div<{ marginLeft?: boolean; minHeight?: string }>`
  display: flex;
  flex-direction: column;
  ${({ marginLeft }) => (marginLeft ? 'margin-left: 7px;' : '')}
`;

const Title = styled.div`
  color: ${Palette.WHITE};
  margin-bottom: 10px;
`;

const TermStatement = styled.span`
  position: relative;
  text-align: start;
  color: ${Palette.WHITE};
  font-size: 16px;
  :before {
    content: '* ';
    color: ${Palette.RED};
    position: absolute;
    left: -10px;
  }
`;

const SubLabel = styled(Title)`
  :before {
    content: '* ';
    color: ${Palette.RED};
  }
`;

const Disclaimer = styled.p`
  color: ${Palette.WHITE};
  font-size: 14px;
  @media ${MediaQuery.tablet} {
    font-size: 16px;
  }
`;

const Button = styled.button`
  min-width: 100px;
  background-color: transparent;
  color: ${Palette.WHITE};
  border: 2px solid ${Palette.WHITE};
  border-radius: 7px;
  margin: auto;
  margin-bottom: 10px;
  padding: 10px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  outline-color: ${Palette.BLUE};

  :disabled {
    pointer-events: none;
  }

  :hover {
    background-color: ${Palette.WHITE};
    color: ${Palette.BLACK};
  }
`;

const TermsLink = styled.a`
  color: ${Palette.WHITE};
  text-decoration: underline;
  :hover {
    color: ${Palette.BLUE};
  }
`;

const ModalContent = styled.div``;

const Hr = styled.hr<{ marginTop?: boolean }>`
  background-color: ${Palette.WHITE};
  border: none;
  height: 1px;
  border-radius: 15px;
  ${({ marginTop }) =>
    marginTop ? 'margin: 15px 0;' : 'margin: 5px 0px 25px 0px;'}
`;

const ScreenReaderLabel = styled.label`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;
